.geosuggest__item  {
    list-style-type: none;
}

#attendence-origin .geosuggest__suggests {
    width: 330px;
}

.wizard > .content > .body {
    width: 100% !important;
}
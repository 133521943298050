
.row-actions-attendance {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.col-actions-attendance {
    display: flex;
}

.col-actions-attendance button {
    flex: 1;
}

#observacao {
    height: 80px;
}

.text-red {
    color: red;
    text-align: justify;
}

.favorite-address {
    min-width: 400px;
}
@media (min-width: 576px) {
  .left-button {
    padding-right: 2px;
  }
  .right-button {
    padding-left: 2px;
  }
}

.swal2-popup {
  font-size: 1.6rem !important;
}

#situacaoAuto {
  display: inline;
  list-style-type: none;
  overflow: hidden;
}

.swal2-modal .swal2-radio label {
  font-weight: normal;
}

.swal2-label {
  margin-left: 5px;
}

.marginSituacao th {
  padding-left: 10px;
  margin-right: 10px;
}

.marginBtnR {
  padding-right: 2px;
  padding-left: 2px;
}

.marginIconSituacao {
  padding-top: 15px;
  margin-bottom: 50px !important;
}

.marginScheduled {
  padding-top: 500px !important;
}

.marginR {
  margin-bottom: 0px !important;
}

.buttonsEditInfo {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
}

.form-horizontal {
  margin-right: 10px;
}

.pdfSS {
  padding: 20px 0;
  display: flex;
  align-content: center;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

.added {
  background-color: #5c40ff;
}

.notAdded {
  background-color: #e76054;
}

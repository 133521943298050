.obsAuto {
  text-align: center;
  margin: 0 auto;
  position: relative;
  vertical-align: middle;
}

#situacaoAuto {
  display: inline;
  list-style-type: none;
  overflow: hidden;
}

#situacaoAuto li {
  float: left;
  padding-left: 20px;
}

.marginSituacao {
  padding-left: 20px;
  padding-top: 8px;
  text-align: center;
  vertical-align: middle;
}

.marginItem {
  padding-left: 5px;
  padding-top: 10px;
}

.marginAcert {
  min-height: 350px;
  max-height: 350px;
}

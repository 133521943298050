.tooltip-patio {
  background-color: #5c40ff !important;
}

.patio-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 192px;
  display: inline-block;
}

.observation-form {
  display: flex;
  margin-top: 16px;
}
.observation-input {
  width: 80%;
}

.observation-button {
  width: 20%;
  margin-left: 10px;
}
.observation-icon {
  font-size: 12px;
  margin-left: 5px;
}

.toggle-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-custonmod {
  background-color: #fff;
  border-color: #d9d9d9;
  color: rgba(11, 10, 10, 0.666);
}

.custom-button {
    width: 110px;
    border-radius: 11px;
}

.partner-inative {
    opacity: 0.95
}

.custom-tel-input {
    width: 60px;
}

.custom-header-panel-label {
    padding-left: 10px;
}

.custom-col {
    background-color: red;
    width: 80px;
    height: 80px;
}

.custom-tab {
    padding-top: 10px;
}

.custom-panel-header {
    height: 100px;
}

.btn-custom {
    border: 0.5px solid #25f4af;
    color: #25f4af;
    background-color: #FFF;
    width: 131px;
    margin-right: 2px;
}

.custom-handle-layers {
    position: relative;
    margin-top: 0;
}

.custom-button-ativo {
    margin-left: 5px;
    width: 90px;
}

.custom-button-popup {
    width: 90px;
}

.custom-nav {
    color: #FFF;
    padding-left: 5px;
}

.custom-nav:hover {
    color: #FFF;
    text-decoration: none;
}

.nav-second-level li a {
    padding: 7px 10px 7px 10px;
    padding-left: 25px;
}

.mini-navbar .nav .nav-second-level {
    position: absolute;
    left: 70px;
    top: 0;
    padding: 0px;
    min-width: 0px;
    background-color: #2f4050;
    font-size: 12px;
}

.NoTop {
    margin-top: 0px;
}

.leaflet-bar {
    cursor: pointer !important;
}

.react-datepicker__close-icon::after {
    background-color: #d9534f !important;
}

.custom-primary {
    background-color: #25f4af !important;
    border-color: #25f4af !important;
    color: #FFFFFF;
}

.custom-link {
    overflow: hidden;
    float: left
}

.custom-link:hover {
    cursor: pointer;
}

.solicitation-active {
    border-left: 5px solid #5c40ff !important;
}

.checkbox {
    padding-left: 20px;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
    opacity: 0;
    z-index: 1;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
}

@media (min-width: 576px) .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.status-circle {
    background-color: #5c40ff;
    border-radius: 4px;
    padding: 3px 10px;
    color: #fff;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .status-circle-off {
    background-color: gray;
  }
.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-container {
  z-index: 1000000 !important;
}

.swall-overlay {
  z-index: 100005;
}
.swal-modal {
  z-index: 99999;
}

.documents-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: minmax(auto, 670px) 1fr;
}

.tagContainer {
  display: flex;
  width: 100%;
  margin-left: 10px;
}

.companyTagContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 5px;
  border: 1px solid #ccc;
  padding: 2px 5px;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .documents-grid {
    display: flex;
    flex-direction: column;
  }
}

.logo-img-soon {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 250px;
  z-index: 1000;
}
.invoice-values {
  font-size: 14px;
  text-align: right;
}

.align-right {
  text-align: right;
}

.cards-container {
  margin-bottom: 125px;
}

.tooltip-dashboard {
  background-color: #5c40ff !important;
}

.custom-toolbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
}

.toolbar-searchPanel {
  width: 470px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.toolbar-btnGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.modal-body-p0 {
    padding: 0 !important;
    height: calc(100vh - 190px);
    position: relative;
    display: flex;
    flex-direction: column;
}

.loader-closest {
    margin: 200px auto;
}

.closest-search-right {
  position: absolute;
  top: 30;
  right: 0;
  margin-top: 8px;
  margin-right: 8px;
  z-index: 9999;
}

.closest-search-container {
  display: flex;
}

@keyframes hover {
  0% {
    opacity: 0;
    left: -20px;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

.container-search-form {
  animation: hover 0.5s;
  flex: 1;
  padding: 10px 0;
}

.closest-map {
  flex: 1;
}

#CLOSEST_PARTNERS_MODAL > .modal-dialog{
    width: 70vw;
}

#CLOSEST_PARTNERS_MODAL  .modal-footer {
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}


.closest-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.closest-footer h4 {
  margin-right: 10px;
}

.fantasma-left {
  display: flex;
}
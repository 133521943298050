.infoContainer{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 10px;
		background-color: #f5f5f5;
		padding: 10px;
}

.infoContainer:first-child{
		margin-top: 20px;
}

.info{
	margin: 5px 0 5px 0;
}
.customDivIcon {
    background: none;
    border: none;
    cursor: pointer;
}

.leaflet-control-search.search-exp{
    width: 300px !important;
    height: 40px !important;
}
.search-input{
    width: 270px !important;
    height: 30px !important;
    font-size: 16px !important;
}

.leaflet-control-search .leaflet-control{
    width: 30px !important;
    height: 30px !important;
}
.leaflet-control-search .search-tooltip {
    overflow-y: unset !important;
    overflow-x: unset !important;
}
a.search-tip {
    height: 30px !important;
}
.leaflet-control-search .search-cancel {
    margin: 8px 0 !important;
    width: 30px;
}

.document--container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 30px 0;
}

.document--image{
    width: 100%;
    height: 50%;
}

.document-card {
    padding: 10px;
    width: 170px;
    height: 260px;
}

.buttons {
    padding: 3px;
    display: flex;
    justify-content: center;
}

.buttons .btn {
    margin-right: 5px;
}

.title {
    padding: 3px;
}
.title p {
    text-align: center;
    font-size: 15px;
}

.modal-dialog-document{
    width: 70vw;
}

.width-80vw {
  width: 80vw;
}

.card__header {
    padding: 16px;
    display: flex;
    background-color: white;
    border-radius: 4px 4px 0 0;


}

.card__header__icon {
    font-size: 40px;
    margin-right: 16px;
}
.card__header__titleContainer {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
}
.card__header__title {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #232F34;
}

.card__header__date {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color:rgba(0, 0, 0, 0.6)
}


.choice-card-body {
    padding: 16px;
    background-color: white;
    border-radius: 0 0 4px 4px;
}

.choice-card {
    border: 1px solid rgba(0,0,0,.125);    
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}